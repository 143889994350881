































import { Component, Vue } from "vue-property-decorator";
import jigsaw from "@/js/jigsaw.js";
import MyForm from "@/components/MyForm.vue";
import Mixin from "@/js/mixins";

// 获取验证码的倒计时
let interval: number;
let time: number = 0;

@Component({ components: { MyForm }, mixins: [Mixin] })
export default class BasicInfo extends Vue {
  public show: boolean = false; // 验证码滑块验证是否显示
  public form_list: MyForm.formList[] = [];
  public password: MyForm.formList[] = [];

  // form组件的回调（值改变时获取数据）
  formChange(form_list: MyForm.formList[]) {
    this.form_list = form_list;
  }
  passwordChange(form_list: MyForm.formList[]) {
    this.password = form_list;
  }
  // 提交表单
  submit() {
    let form: any = this.$refs.form;
    let password: any = this.$refs.password;
    let boolean1 = form.ValidateAll();
    let boolean2 = password.ValidateAll();
    if (boolean1 && boolean2) {
      console.log(this.$api.getSession("openid"));
      this.submitRequest(
        this.$api.getFormData([this.form_list, this.password])
      );
    }
  }
  // 提交表单（请求接口）
  submitRequest(data: Dictionary<string>) {
    let _this = this;

    let openid = this.$api.getSession("openid");
    let url = "user/member/security-info";
    if (openid) {
      data.openid = openid;
      url = "common/sms/register-moblie";
    }
    this.$api.request({
      url,
      data,
      success(res) {
        if (openid) {
          _this.$api.removeSession("openid");
          _this.$api.setSession("token", res.data.access_token);
        }
        _this.$api.href("/");
      }
    });
  }
  // 获取验证码
  getCode() {
    if (time) {
      return;
    }
    let form: any = this.$refs.form;
    form.Validate(0);
    if (!form.error.mobile) {
      this.show = true;
    }
  }
  // 60秒倒计时
  countDown() {
    interval = setInterval(() => {
      if (time === 0) {
        clearInterval(interval);
        return this.$set(this.form_list[0], "right_btn", "获取验证码");
      }
      this.$set(this.form_list[0], "right_btn", String(time));
      time--;
    }, 1000);
  }
  // 获取用户基本信息（如有，则对应的输入框不显示）
  getUserInfo() {
    let _this = this;
    this.$api.request({
      url: "user/member/info-perfection",
      success(res) {
        let data = res.data;
        if (data.mobile) {
          _this.removeFormItem("mobile", data.mobile);
          _this.removeFormItem("code");
        }
        if (data.password) {
          _this.removeFormItem("password");
        }
      }
    });
  }
  // 删除或禁用输入项
  removeFormItem(name: string, value?: string) {
    if (name === "password") {
      return this.password.splice(0, 1);
    }
    this.form_list.forEach((item, index) => {
      if (item.name === name) {
        this.form_list.splice(index, 1);
      }
    });
  }

  mounted() {
    jigsaw.init({
      el: document.getElementById("captcha"),
      onSuccess: () => {
        this.$api.sendCode(
          this.$api.getFormData([this.form_list, this.password]).mobile,
          () => {
            time = 60;
            this.show = false;
            this.countDown();
          }
        );
      },
      onFail: () => {
        console.log("失败");
      }
    });
  }

  init() {
    this.show = false;
    this.form_list = [
      {
        name: "mobile",
        value: "",
        label: "手机号码",
        type: "input",
        input_type: "number",
        placeholder: "请输入您的手机号码",
        required: true,
        right_btn: "获取验证码",
        right_bind: this.getCode,
        validate: "phone"
      },
      {
        name: "code",
        value: "",
        label: "验证码",
        type: "input",
        input_type: "number",
        placeholder: "请输入验证码",
        required: true
      }
    ];
    this.password = [
      {
        name: "password",
        value: "",
        label: "设置密码",
        type: "password",
        mask: false,
        placeholder: "请输入推荐人手机号码",
        required: true,
        validate_min: 6,
        validate_max: 6,
        label_center: true
      }
    ];
    this.$api.refreshForm([this.$refs.form, this.$refs.password]);
    if (!this.$api.getSession("openid")) {
      // 有openid时不请求
      this.getUserInfo();
    }
  }
}
